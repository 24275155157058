.login .warning-disabled {
    display: none;
}

.login .warning-enabled {
    display: block;
    color: var(--sec-color-light);
    margin: 10px 0;
}

.login {
    display: flex;
    height: 100%;
}

.login-container {
    margin: 50px auto;
    border: solid;
    border-radius: 5px;
    padding: 30px 70px;
    background-color: var(--pri-color-dark);
}

@media screen and (max-width: 600px) {
    .login-container {
        margin: 10px auto;
        border: solid;
        border-radius: 5px;
        padding: 10px 20px;
        background-color: var(--pri-color-dark);
    }
}


.login form>label {
    color: var(--text-color-light);
}

.login form>* {
    display: block;
    color: var(--text-color-dark);
}

.login button {
    margin: 20px auto 0;
    background-color: var(--background-light);
    padding: 5px 30px;
    border-radius: 5px;
    border: none;
}