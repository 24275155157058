
.ingredient-modal {
    background-color: var(--background-light);
    padding: 20px 30px;
    border-radius: 5px;
}

.ingredient-modal-inputs > div {
    margin: 10px 0;
}

.ingredient-modal-inputs > div > label {
    margin-right: 5px;
}
.ingredient-modal-inputs input {
    box-sizing: border-box;
    width: 100%;
}

.ingredient-modal-confirmation button{
    width: 150px;
    height: 30px;
    margin: 5px;
}

.ingredient-modal-confirmation {
    text-align: center;
}
