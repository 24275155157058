:root {
  --pri-color-dark: #264653;
  --pri-color-light: #2a9d8f;
  --sec-color-lightest: #e9c46a;
  --sec-color-light: #f4a261;
  --sec-color-dark: #e76f51;
  --text-color-light: #eee;
  --text-color-dark: #222;
  --background-dark: #333;
  --background-light:#ddd;

  color: var(--text-color-dark);
  font-family: Arial, Helvetica, sans-serif;  

  font-size: 16px;
}

html, body {
  height: 100%;
  background-color: var(--background-light);
}

label {
  font-size:18px;
}

input {
  background-color: var(--background-light);
  color: var(--text-color-dark);
  font-size: 16px;
  border-radius: 5px;
}

.button-link {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 100px;
  height: 30px;
  background-color: var(--pri-color-dark);   
  color: var(--text-color-light);
  text-align: center;
  border-radius: 5px;
  padding-top:8px;
  text-decoration: none;
  font-weight: bold;
}

.button-link:hover {
  background-color: var(--pri-color-light);
}

.popup-overlay {
  background-color: #000d;
}

.hidden {
  display: none;
}

