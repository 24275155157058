

.recipe-page .recipe-header {
    text-align: center;
}

.recipe-page {
    display:flex;
    width: 100%;
    justify-content: center;
    
}

.recipe-container {
    color: var(--text-color-light);
    background-color: var(--background-dark);
    
    margin: 10px;
    border-radius: 5px;
    padding: 0 30px 20px;
    width: 100%;
}

.recipe-list {
    margin: 20px 0 0;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

.recipe-tile {
    color: var(--text-color-light);
    border-radius: 5px;
    
    text-align: center;
    padding: 14px 0 0;
    font-size: 18px;
    text-transform: capitalize;
}

.recipe-list a {
    width: 300px;
    height: 50px;
    margin:5px;
    background-color: var(--pri-color-dark);
    color: var(--text-color-light);
    text-decoration: none;
    font-weight: bold;
}

.recipe-list a:hover {
    background-color: var(--pri-color-light);
}
