
.recipe-popup-overlay {
    background-color: #000d;
    padding:10px;
}

.recipe-popup-content {
    max-width:800px;
    width: 90%;
    margin: 10px;
    max-height: 90%;
    overflow: scroll;
    padding: 20px;
    background-color: var(--background-light);
}

.recipe-modal {
    width: 100%;
}

.recipe-modal-line {
    display:flex;
    justify-content: center;
    align-items: center;
}

.recipe-modal-line > * {
    display:block;
    width: 100px;
    margin: 2px 5px;
}

.recipe-modal-line > .grow {
    flex-grow: 1;
}

.recipe-modal-line > .add-button {
    width: 300px;
    height: 30px;
}

.recipe-modal-confirm {
    display:flex;
    justify-content: right;
}

.recipe-modal-confirm button {
    width:150px;
    height: 30px;
    margin: 5px;
}
