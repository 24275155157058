.topnav {
    overflow: hidden;
    background-color: var(--background-dark);
}

.topnav a {
    float: left;
    display: block;
    color: var(--text-color-light);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav a:hover {
    background-color: var(--pri-color-light);
    color: var(--text-color-dark);
}

.topnav .icon {
    display: none;
}

@media screen and (max-width: 600px) {

    .topnav a:not(:first-child) {
        display: none;
    }

    .topnav a.icon {
        float: right;
        display: block;
    }

    .topnav.responsive {
        position: relative;
    }

    .topnav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}