.input__wrapper {
    position: relative;
}

.input__wrapper .suggestions__dropdown {
    position: absolute;
    top: 30px;
    right: 0;
    left: 0;
    width: 100%;
    height: 100px;
    overflow-y: auto;
}