

.ingredients-page {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom:20px;
}

.ingredient-item {
    position: relative;
    background-color: var(--pri-color-dark);
    color: var(--text-color-light);
    font-weight: bold;
    width: 600px;
    max-width: 90%;
    height:30px;
    margin: 5px 0;
    padding-top:9px;
    border-radius: 5px;
    flex-basis: 0 0 0;
}

.ingredient-name {
    width: 50%;
    display: inline-block;
    text-align: right;
    padding-right:10px;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space: nowrap;
}

.ingredient-density {
    width: 25%;
    display: inline-block;
    padding-right:10px;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space: nowrap;
}

.ingredient-item-controls {
    display: block;
    position: absolute;
    top:0;
    right:0;
}

.ingredient-item-controls button {
    width:25px;
    height: 39px;
    background-color: var(--pri-color-dark);
    border:none;
    color: var(--text-color-light);
    cursor: pointer;
}

.ingredient-header h1 {
    text-align: center;
}

.ingredient-header {
    margin-bottom:20px;
    max-width: 100%;
    overflow:hidden;
    padding:5px;
    box-sizing: border-box;
}

.ingredient-header input {
    max-width: 90%;
}



