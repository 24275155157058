

.confirm-modal {
    background-color: var(--pri-color-dark);
    color: var(--text-color-light);
    padding: 20px 30px;
    border-radius: 5px;
}

.confirm-modal .button-panel {
    display: flex;
    justify-content: center;
}

.confirm-modal .button-panel button {
    width: 100px;
    height: 30px;
    margin: 5px;
    cursor: pointer;
}

