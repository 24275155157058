.recipe-details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--background-dark);
    color: var(--text-color-light);
    display: flex;
    overflow: hidden;
    padding: 20px 0px;
}

.recipe-ingredients {
    min-width: 30%;
    padding: 20px;
    overflow: visible scroll;
    font-size: 1.7em;
}

.recipe-ingredients table {
    width: 100%;
}

.recipe-ingredients table td {
    padding-top: 10px;
    vertical-align: top;
}

.recipe-ingredients .ingredient-qty {
    padding-right: 10px;
    white-space: nowrap;
}

.recipe-ingredients .ingredient-name {
    text-align: left;
    white-space: break-spaces;
    width:100%;
}

.recipe-instructions {
    flex-grow: 1;
    padding: 10px;
    overflow: visible scroll;
    border-left:solid;
    border-color: var(--sec-color-dark);
    font-size: 1.7em;
}

.recipe-instructions h1 {
    text-transform: capitalize;
}

.recipe-controls {
    position:absolute;
    right:5px;
    bottom:5px;
}

.recipe-details button {
    width:200px;
    height:30px;
    margin:5px;
    border-radius: 5px;
    border:none;
    background-color: var(--pri-color-dark);
    color: var(--text-color-light);
    font-weight: bold;
    cursor: pointer;
}

.recipe-controls button {
    width:200px;
    height:90px;
    margin:10px;
    font-size:3em;
}

.recipe-details button:hover {
    background-color: var(--pri-color-light);
}

.recipe-subassembly {
    color: var(--text-color-light);
}



@media screen and (max-width: 600px) {
    .recipe-details {
        flex-direction: column;
    }

    .recipe-instructions {      
        border-left:none;
        border-top:solid;
        border-color: var(--sec-color-dark);
        font-size: 1em;
    }

    .recipe-ingredients {
        font-size: 1em;
    }

    
    
    .recipe-details {
        position:relative;
    }

}